import { Component, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
// import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import * as moment from "moment";
import fr from "moment/locale/fr";
declare var jQuery: any;
moment.defineLocale("fr", fr);
import { Subject } from "rxjs";

import { UsersService } from "src/app/services/users/users.service";
import { GeneralService } from "src/app/services/general/general.service";

@Component({
  selector: "app-list-chauffeurs",
  templateUrl: "./list-chauffeurs.component.html",
  styleUrls: ["./list-chauffeurs.component.css"],
})
export class ListChauffeursComponent implements OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  chauffx: any = [];
  currChauff: any = {};

  comdatas: any = { userid: "", comment: "" };

  dtOptions: DataTables.Settings = {};
  dtTrigger: any = new Subject();

  currentDtFilter: any = {};

  constructor(
    private router: Router,
    private generalsrv: GeneralService,
    private userSrvs: UsersService
  ) {}

  ngOnInit() {
    this.loadChauffeurs();
  }
  loadAllChauffeurs() {
    this.userSrvs
      .listeChauffeurs({ start: 0, length: 999999999, search: {}, sort: {} })
      .then((resp: any) => {
        if (resp) {
          this.chauffx = resp.data;
        } else {
        }
      });
  }
  loadChauffeurs() {
    this.dtOptions = {
      order: [[1, "desc"]],
      pagingType: "full_numbers",
      pageLength: 999999,
      serverSide: true,
      processing: true,
      columnDefs: [
        {
          targets: 0,
          orderable: false,
        },
        {
          targets: 7,
          orderable: false,
        },
        {
          targets: 2,
          orderable: false,
        },
        {
          targets: 3,
          orderable: false,
        },
        {
          targets: 4,
          orderable: false,
        },
        {
          targets: 5,
          orderable: false,
        },
        {
          targets: 6,
          orderable: false,
        },
      ],
      language: this.generalsrv.dataTableLanguage,
      ajax: (dataTablesParameters: any, callback) => {
        this.currentDtFilter = dataTablesParameters;
        this.userSrvs
          .listeChauffeurs(dataTablesParameters)
          .then((resp: any) => {
            if (resp) {
              this.chauffx = resp.data;
              // console.log(resp.data.recordsTotal);
              callback({
                recordsTotal: resp.recordsTotal,
                recordsFiltered: resp.recordsFiltered,
                data: [],
              });
            } else {
            }
          });
      },
    };
  }
  exportChauffs() {
    // console.log(this.currentDtFilter);
    this.currentDtFilter.isExport = true;
    this.userSrvs.listeChauffeurs(this.currentDtFilter).then((resp: any) => {
      if (resp && resp.data) {
        // console.log(resp.data.length)
        // console.table(resp.data)
        this.downloadFile(resp.data);
      } else {
      }
    });
  }
  downloadFile(data: any) {
    // console.log(data);
    const blob = new Blob(
      [
        new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
        data,
      ],
      { type: "text/csv;charset=ISO-8859-1" }
    );
    const url = window.URL.createObjectURL(blob);
    var anchor = document.createElement("a");
    anchor.download = "export_chauffeurs.csv";
    anchor.href = url;
    anchor.click();
  }
  detailsChauff(chaff) {
    if (chaff) {
      var chff = this.chauffx.findIndex((x) => x.id.toString() == chaff);

      if (chff != -1) {
        this.currChauff = this.chauffx[chff];
        console.log(this.currChauff);
        jQuery("#detailsChauffeurModal").modal("show");
      } else {
        console.log("No chauffeur");
      }
    }
  }
  deleteSct(sctID) {
    if (sctID) {
      // jQuery("#delclientmodal").modal("show");
      // this.clienToDel = clientID;
    }
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
    });
  }
  deleteChauff(id) {
    this.generalsrv
      .confirmMsg("Etes vous sure de vouloir supprimer ce chauffeur")
      .then((rep) => {
        if (rep) {
          this.userSrvs.deleteChauff(id).then((isok: any) => {
            console.log(isok);
            if (isok && isok.response) {
              this.generalsrv.notifyMsg(
                "success",
                "Chauffeur supprimé avec succès"
              );
              this.rerender();
            }
          });
        }
      });
  }
  updateChauffStatus(newstatus) {
    this.generalsrv
      .confirmMsg("Etes vous sure de vouloir de changer le status?")
      .then((rep) => {
        if (rep) {
          this.userSrvs
            .updateChauffStatus({ user: this.currChauff.id, newstatus })
            .then((isok: any) => {
              console.log(isok);
              if (isok && isok.response) {
                this.generalsrv.notifyMsg(
                  "success",
                  "Chauffeur mis à jour avec succès"
                );
                jQuery("#detailsChauffeurModal").modal("hide");
                this.rerender();
              }
            });
        }
      });
  }
  displayAddCommentaire(idchff) {
    if (idchff) {
      var chff = this.chauffx.findIndex((x) => x.id.toString() == idchff);

      if (chff != -1) {
        this.comdatas.userid = idchff;
        this.comdatas.comment = this.chauffx[chff].comment_user;
        // console.log(this.currChauff);
        jQuery("#addcommentmodal").modal("show");
      } else {
        console.log("No chauffeur");
      }
    }
  }
  addCommentaire() {
    this.userSrvs.addCommentaire(this.comdatas).then((isok: any) => {
      console.log(isok);
      if (isok && isok.response) {
        this.comdatas = { userid: "", comment: "" };
        this.generalsrv.notifyMsg("success", "Commentaire ajouté avec succès");
        jQuery("#addcommentmodal").modal("hide");
        this.rerender();
      }
    });
  }
}
