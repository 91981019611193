import { Component, OnInit } from "@angular/core";
declare var jQuery: any;
@Component({
  selector: "app-top",
  templateUrl: "./top.component.html",
  styleUrls: ["./top.component.css"],
})
export class TopComponent implements OnInit {
  nom: String = "";
  codeparrainage: String = "";
  constructor() {}

  ngOnInit(): void {
    try {
      var lstrg = localStorage.getItem("userdetails");
      if (lstrg) {
        let user = JSON.parse(lstrg);
        if (user && user.name) {
          this.nom = user.name;
          this.codeparrainage=user.code_parrainage
        }
      }
    } catch (error) {}
  }
  openMenu() {
    jQuery("body").toggleClass("sidebar-gone");
  }
}
