import { Component, OnInit } from "@angular/core";
import { BannersService } from "src/app/services/banners/banners.service";
declare var jQuery: any;

@Component({
  selector: "app-banners",
  templateUrl: "./banners.component.html",
  styleUrls: ["./banners.component.css"],
})
export class BannersComponent implements OnInit {
  banners: any = [];
  newBanner: any = { img: "", order: 0 };
  actualBanner: any = { img: "", order: 0 };
  bannertodel: string;
  file: File = null;
  constructor(private banssrv: BannersService) {}

  ngOnInit() {
    this.loadBanners();
  }
  bindFileInputFile(event) {
    this.file = event.target.files[0];
  }
  loadBanners() {
    this.banssrv.getBanners({}).then((bans: any) => {
      if (bans && bans.length) {
        this.banners = bans;
      }else{
        this.banners=[]
      }
    });
  }
  addBanner(isSubmit = false) {
    if (!isSubmit) {
      jQuery("#addbanner").modal("show");
    } else {
      this.banssrv.uploadBanner(this.file).then((uploadFileName) => {
        if (uploadFileName) {
          this.newBanner.img = uploadFileName;

          this.banssrv.addBanner(this.newBanner).then((rep) => {
            if (rep) {
              this.newBanner = { img: "", order: 0 };
              jQuery("#addbanner").modal("hide");
              this.loadBanners();
            }
          });
        }
      });
    }
  }
  updateBanner(x) {
    // console.log(this.banners[x])
    if (!this.banners[x] || !this.banners[x].id) {
      return false;
    }
    this.banssrv
      .updateBanner({ idban: this.banners[x].id, order: this.banners[x].ordre })
      .then((newbans) => {
        if (newbans) {
          this.loadBanners();
        }
      });
  }
  deleteBanner(x) {
    if (!this.banners[x] || !this.banners[x].id) {
      return false;
    }
    this.bannertodel = this.banners[x].id;
    jQuery("#delbanner").modal("show");
  }
  confirmDeleteBanner() {
    jQuery("#delbanner").modal("hide");
    this.banssrv.deleteBanner({ idban: this.bannertodel }).then((isok) => {
      if (isok) {
        this.loadBanners();
      }
    });
  }
}
