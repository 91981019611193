import { Component, OnInit } from "@angular/core";
//import jwt_decode from "jwt-decode";
import { UsersService } from "src/app/services/users/users.service";
declare var jQuery: any;
@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.css"],
})
export class MenuComponent implements OnInit {
  profile: String = "";
  constructor(private usersrv: UsersService) {}

  ngOnInit(): void {
    var userdetails = localStorage.getItem("userdetails");

    if (userdetails) {
      // console.log(userdetails);
      // var decode = jwt_decode(JSON.parse(userdetails).token);
      // console.log("Decoded:" + JSON.stringify(decode));
      //   this.usersrv.getPofile().then((prf: any) => {
      //     //console.log(prf);
      //     if (prf) {
      //       this.profile = prf;
      //     } else {
      //       // console.log("NOT PRORIFOFO");
      //       this.exitPage();
      //     }
      //   });
      // } else {
      //   //console.log("not token");
      //   this.exitPage();
    }
  }
  exitPage() {
    return;
    // console.log("Exited");
    localStorage.clear();
    location.href = "/";
  }
  openMenu() {
    if (jQuery("window").width < jQuery("window").height)
      jQuery("body").addClass("sidebar-gone");
    else jQuery("body").removeClass("sidebar-gone");
  }
}
