import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { AddSctTrasportComponent } from "./pages/add-sct-trasport/add-sct-trasport.component";
import { BannersComponent } from "./pages/banners/banners.component";
import { CoursesRelanceeComponent } from "./pages/courses-relancee/courses-relancee.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { ListChauffeursComponent } from "./pages/list-chauffeurs/list-chauffeurs.component";
import { ListCoursesComponent } from "./pages/list-courses/list-courses.component";
import { ListHopitauxComponent } from "./pages/list-hopitaux/list-hopitaux.component";
import { ListSctAssistanceComponent } from "./pages/list-sct-assistance/list-sct-assistance.component";
import { ListSctTransportComponent } from "./pages/list-sct-transport/list-sct-transport.component";
import { PaymentsComponent } from "./pages/payments/payments.component";

const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "dashboard", component: DashboardComponent },

  { path: "addsctstrs", component: AddSctTrasportComponent },
  { path: "sctstrans", component: ListSctTransportComponent },

  { path: "hopitaux", component: ListHopitauxComponent },

  { path: "assistance", component: ListSctAssistanceComponent },

  { path: "chauffeurs", component: ListChauffeursComponent },
  { path: "courses", component: ListCoursesComponent },
  { path: "relancees", component: CoursesRelanceeComponent },

  { path: "payments", component: PaymentsComponent },

  { path: "banners", component: BannersComponent },

  { path: "logout", redirectTo: "/login" },
  { path: "**", redirectTo: "/dashboard", pathMatch: "full" },
];
const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: "enabled",
  // ...any other options you'd like to use
};
@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
