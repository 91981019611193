import { Component, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import * as moment from "moment";
import fr from "moment/locale/fr";
declare var jQuery: any;
moment.defineLocale("fr", fr);
import { Subject } from "rxjs";

import { UsersService } from "src/app/services/users/users.service";
import { GeneralService } from "src/app/services/general/general.service";
@Component({
  selector: "app-list-hopitaux",
  templateUrl: "./list-hopitaux.component.html",
  styleUrls: ["./list-hopitaux.component.css"],
})
export class ListHopitauxComponent implements OnInit {
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  geocompleteoptions: any = {
    types: [],
    componentRestrictions: { country: "FR" },
  };

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  hopitx: any = [];
  dtOptions: DataTables.Settings = {};
  dtTrigger: any = new Subject();
  newHopit: any = {};
  comdatas: any = { userid: "", comment: "" };
  constructor(
    private router: Router,
    private generalsrv: GeneralService,
    private userSrvs: UsersService
  ) {}

  ngOnInit() {
    this.loadHopitaux();
  }
  loadHopitaux() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 50,
      serverSide: true,
      processing: true,
      ordering: false,
      language: this.generalsrv.dataTableLanguage,
      ajax: (dataTablesParameters: any, callback) => {
        this.userSrvs.listeHopitaux(dataTablesParameters).then((resp: any) => {
          if (resp) {
            this.hopitx = resp.data;
            // console.log(resp.data);
            callback({
              recordsTotal: resp.data.length,
              recordsFiltered: resp.data.length,
              data: [],
            });
          } else {
          }
        });
      },
    };
  }
  handleAddressChange(address) {
    if (address && address.formatted_address && address.address_components) {
      let { formatted_address, geometry, address_components } = address;
      this.newHopit.geodatas = {
        formatted_address,
        geometry,
        address_components,
      };
      this.newHopit.adresse = formatted_address;
      this.newHopit.coords = geometry.location;

      address.address_components.forEach((address_component) => {
        if (address_component.types[0] == "locality") {
          this.newHopit.ville = address_component.long_name;
        }
        if (address_component.types[0] == "postal_code") {
          console.log("pc:" + address_component.long_name);
          this.newHopit.codepostal = address_component.long_name;
        }
      });
    } else {
      this.newHopit.coords = {};
    }
  }
  addHopital() {
    jQuery("#newHopitModal").modal("show");
  }
  submitHopital() {
    this.userSrvs.addHoptal(this.newHopit).then((v) => {
      if (v) {
        jQuery("#newHopitModal").modal("hide");
        this.newHopit = {};
        this.rerender();
      }
    });
  }
  deleteSct(sctID) {
    if (sctID) {
      // jQuery("#delclientmodal").modal("show");
      // this.clienToDel = clientID;
    }
  }
  // confirmDeleteClient() {
  //   if (this.clienToDel) {
  //     this.clientsSrv.deleteClient(this.clienToDel).then((r) => {
  //       if (r) {
  //         jQuery("#delclientmodal").modal("hide");
  //         // this.router.navigate(["/clients"]);
  //         this.rerender();

  //         // this.loadClients();
  //         // this.dtTrigger.next();
  //       }
  //     });
  //   } else {
  //   }
  // }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => { 
      dtInstance.ajax.reload();
    });
  }

  displayAddCommentaire(idhop) {
    if (idhop) {
      var chff = this.hopitx.findIndex((x) => x.id.toString() == idhop);

      if (chff != -1) {
        this.comdatas.userid = idhop;
        this.comdatas.comment = this.hopitx[chff].comment_user;
        // console.log(this.currChauff);
        jQuery("#addcommentmodal").modal("show");
      } else {
        console.log("No chauffeur");
      }
    }
  }
  addCommentaire() {
    this.userSrvs.addCommentaire(this.comdatas).then((isok: any) => {
      console.log(isok);
      if (isok && isok.response) {
        this.comdatas = { userid: "", comment: "" };
        this.generalsrv.notifyMsg("success", "Commentaire ajouté avec succès");
        jQuery("#addcommentmodal").modal("hide");
        this.rerender();
      }
    });
  }

}
