import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";
import { GeneralService } from "./general/general.service";

@Injectable({
  providedIn: "root",
})
export class CoursesService {
  constructor(
    private httpClient: HttpClient,
    private generalSrv: GeneralService
  ) {}
  listeCrouses(filter) {
    return new Promise((solve) => {
      this.httpClient.post(environment.apiListCourses, filter).subscribe(
        (res: any) => {
          if (res && res.response) {
            solve(res);
          } else {
            if (res.msg) this.generalSrv.notifyMsg("error", res.msg);
            else
              this.generalSrv.notifyMsg("error", "Une erreur s'est produite");
            solve(false);
          }
        },
        (err) => {
          solve(false);
        }
      );
    });
  }
  dashboardstats(filter) {
    return new Promise((solve) => {
      this.httpClient.post(environment.apiDashboardStat, filter).subscribe(
        (res: any) => {
          if (res && res.response) {
            solve(res);
          } else {
            if (res.msg) this.generalSrv.notifyMsg("error", res.msg);
            else
              this.generalSrv.notifyMsg("error", "Une erreur s'est produite");
            solve(false);
          }
        },
        (err) => {
          solve(false);
        }
      );
    });
  }
}
