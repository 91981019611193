import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DataTableDirective } from "angular-datatables";

declare var jQuery: any;
moment.defineLocale("fr", fr);
import { Subject } from "rxjs";

import * as moment from "moment";
import fr from "moment/locale/fr";
import { isThisWeek } from "date-fns";
import { GeneralService } from "src/app/services/general/general.service";
import { CoursesService } from "src/app/services/courses.service";
declare var jQuery: any;
declare var Chart: any;
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  @ViewChild("chart") canvas: ElementRef;
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  lstcourses: any = [];
  currCourse: any = {};

  dtOptions: DataTables.Settings = {};
  dtTrigger: any = new Subject();
  moreFilter: any = {};
  stats: any = {
    totalAttente: 0,
    totalAccepte: 0,
    totalFini: 0,
    total: 0,
    totalCourseRelances: 0,
    totalChauff: 0,
    totalCA: 0,
  };
  filtreStat: any = {
    date:
      moment().startOf("day").format("DD/MM/YYYY") +
      " - " +
      moment().endOf("day").format("DD/MM/YYYY"),
    etat: "ATTENTE",
  };

  constructor(
    private grnsrv: GeneralService,
    private coursesSrv: CoursesService
  ) {}

  ngOnInit(): void {
    jQuery(".daterange-btn").daterangepicker(
      {
        locale: this.grnsrv.dateTimePickerLocale["fr"],
        onChange: function (start, end) {
          console.log("aaze");

          // var tempValues = JSON.parse($('#daterangepicker').val());
          // alert('change : ' + tempValues.start + ' - ' + tempValues.end)
        },
        // ranges: {
        //   Today: [moment(), moment()],
        //   Yesterday: [
        //     moment().subtract(1, "days"),
        //     moment().subtract(1, "days"),
        //   ],
        //   "Dernière semaine": [moment().subtract(6, "days"), moment()],
        //   "30 derniers jour": [moment().subtract(29, "days"), moment()],
        //   "Ce mois ci": [moment().startOf("month"), moment().endOf("month")],
        //   "Mois dernier": [
        //     moment().subtract(1, "month").startOf("month"),
        //     moment().subtract(1, "month").endOf("month"),
        //   ],
        // },
        // startDate: moment().subtract(29, "days"),
        // endDate: moment(),
      },
      (start, end) => this.dateTimeCB(start, end)
    );
    this._loadStats();
    this.loadCourses();
  }
  dateTimeCB(start, end) {
    console.log("start " + start);
    $(".daterange-btn span").html(
      start.format("DD/MM/YYYY") + " - " + end.format("DD/MM/YYYY")
    );
    this.filtreStat.date =
      start.format("DD/MM/YYYY") + " - " + end.format("DD/MM/YYYY");
    this._loadStats();
    this.rerender();
  }
  _loadStats() {
    this.coursesSrv.dashboardstats(this.filtreStat).then((d: any) => {
      if (d.data) {
        this.stats = d.data;
      }
    });
  }
  loadCourses() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 50,
      serverSide: true,
      processing: true,
      ordering: false,
      language: this.grnsrv.dataTableLanguage,
      ajax: (dataTablesParameters: any, callback) => {
        if (this.moreFilter) dataTablesParameters.moreFilter = this.filtreStat;

        this.coursesSrv.listeCrouses(dataTablesParameters).then((resp: any) => {
          if (resp) {
            if (resp.data) {
              this.lstcourses = resp.data.table;
              // console.log(resp.data);
              callback({
                recordsTotal: resp.data.total,
                recordsFiltered: resp.data.total,
                data: [],
              });
            }
          } else {
          }
        });
      },
    };
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
    });
  }
  detailsCourse(chaff) {
    if (chaff) {
      var crss = this.lstcourses.findIndex((x) => x.id.toString() == chaff);

      if (crss != -1) {
        this.currCourse = this.lstcourses[crss];

        jQuery("#detailsCourseModal").modal("show");
      } else {
        console.log("No chauffeur");
      }
    }
  }
  _loadChart() {
    var ctx: any = document.getElementById("myChart");
    ctx.getContext("2d");
    var myChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
        ],
        datasets: [
          {
            label: "Sales",
            data: [3200, 1800, 4305, 3022, 6310, 5120, 5880, 6154],
            borderWidth: 2,
            backgroundColor: "rgba(63,82,227,.8)",

            borderColor: "transparent",
            pointBorderWidth: 0,
            pointRadius: 3.5,
            pointBackgroundColor: "transparent",
            pointHoverBackgroundColor: "rgba(63,82,227,.8)",
          },
          {
            label: "Budget",
            data: [2207, 3403, 2200, 5025, 2302, 4208, 3880, 4880],
            borderWidth: 2,
            backgroundColor: "rgba(254,86,83,.7)",

            borderColor: "transparent",
            pointBorderWidth: 0,
            pointRadius: 3.5,
            pointBackgroundColor: "transparent",
            pointHoverBackgroundColor: "rgba(254,86,83,.8)",
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                // display: false,
                drawBorder: false,
                color: "#f2f2f2",
              },
              ticks: {
                beginAtZero: true,
                stepSize: 1500,
                callback: function (value, index, values) {
                  return "$" + value;
                },
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
                tickMarkLength: 15,
              },
            },
          ],
        },
      },
    });

    var balance_chart: any = document.getElementById("balancechart");
    balance_chart.getContext("2d");

    // var balance_chart_bg_color: any = balance_chart.createLinearGradient(
    //   0,
    //   0,
    //   0,
    //   70
    // );
    // balance_chart_bg_color.addColorStop(0, 'rgba(63,82,227,.2)');
    // balance_chart_bg_color.addColorStop(1, 'rgba(63,82,227,0)');

    var myChart = new Chart(balance_chart, {
      type: "line",
      data: {
        labels: [
          "16-07-2018",
          "17-07-2018",
          "18-07-2018",
          "19-07-2018",
          "20-07-2018",
          "21-07-2018",
          "22-07-2018",
          "23-07-2018",
          "24-07-2018",
          "25-07-2018",
          "26-07-2018",
          "27-07-2018",
          "28-07-2018",
          "29-07-2018",
          "30-07-2018",
          "31-07-2018",
        ],
        datasets: [
          {
            label: "Balance",
            data: [
              50,
              61,
              80,
              50,
              72,
              52,
              60,
              41,
              30,
              45,
              70,
              40,
              93,
              63,
              50,
              62,
            ],
            // backgroundColor: balance_chart_bg_color,
            borderWidth: 3,
            borderColor: "rgba(63,82,227,1)",
            pointBorderWidth: 0,
            pointBorderColor: "transparent",
            pointRadius: 3,
            pointBackgroundColor: "transparent",
            pointHoverBackgroundColor: "rgba(63,82,227,1)",
          },
        ],
      },
      options: {
        layout: {
          padding: {
            bottom: -1,
            left: -1,
          },
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                beginAtZero: true,
                display: false,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                display: false,
              },
              ticks: {
                display: false,
              },
            },
          ],
        },
      },
    });

    var sales_chart: any = document.getElementById("sales-chart");
    sales_chart.getContext("2d");

    // var sales_chart_bg_color = sales_chart.createLinearGradient(0, 0, 0, 80);
    // balance_chart_bg_color.addColorStop(0, 'rgba(63,82,227,.2)');
    // balance_chart_bg_color.addColorStop(1, 'rgba(63,82,227,0)');

    var myChart = new Chart(sales_chart, {
      type: "line",
      data: {
        labels: [
          "16-07-2018",
          "17-07-2018",
          "18-07-2018",
          "19-07-2018",
          "20-07-2018",
          "21-07-2018",
          "22-07-2018",
          "23-07-2018",
          "24-07-2018",
          "25-07-2018",
          "26-07-2018",
          "27-07-2018",
          "28-07-2018",
          "29-07-2018",
          "30-07-2018",
          "31-07-2018",
        ],
        datasets: [
          {
            label: "Sales",
            data: [
              70,
              62,
              44,
              40,
              21,
              63,
              82,
              52,
              50,
              31,
              70,
              50,
              91,
              63,
              51,
              60,
            ],
            borderWidth: 2,
            // backgroundColor: balance_chart_bg_color,

            borderColor: "rgba(63,82,227,1)",
            pointBorderWidth: 0,
            pointBorderColor: "transparent",
            pointRadius: 3,
            pointBackgroundColor: "transparent",
            pointHoverBackgroundColor: "rgba(63,82,227,1)",
          },
        ],
      },
      options: {
        layout: {
          padding: {
            bottom: -1,
            left: -1,
          },
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                beginAtZero: true,
                display: false,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                display: false,
              },
              ticks: {
                display: false,
              },
            },
          ],
        },
      },
    });
  }
}
