import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";
import { GeneralService } from "../general/general.service";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  constructor(
    private httpClient: HttpClient,
    private generalSrv: GeneralService
  ) {}
  getUserInfos() {
    let user = localStorage.getItem("userdetails");

    if (user && typeof user != undefined && JSON.parse(user))
      return JSON.parse(user);
    return null;
  }
  saveUserCreds(usercreds) {
    localStorage.setItem("userdetails", JSON.stringify(usercreds));
  }
  getPofile() {
    return new Promise((solve) => {
      this.httpClient.post(environment.apiProfileUsers, {}).subscribe(
        (res: any) => {
          if (res) {
            if (res.response && res.profile) {
              solve(res.profile);
            }
            solve(false);
          } else {
            solve(false);
          }
        },
        (err) => {
          solve(false);
        }
      );
    });
  }
  login(creds) {
    return new Promise((solve) => {
      this.httpClient.post(environment.apiAuthUsers, creds).subscribe(
        (res: any) => {
          if (res) {
            if (res.response && res.userdetails) {
              this.saveUserCreds(res.userdetails);
              solve(res);
            }
            solve(res);
          } else {
            solve(res);
          }
        },
        (err) => {
          solve(false);
        }
      );
    });
  }
  addStcTransport(newSctTransp) {
    return new Promise((solve) => {
      this.httpClient
        .post(environment.apiAddSctTransport, newSctTransp)
        .subscribe(
          (res: any) => {
            // solve(res);
            if (res && res.response) {
              this.generalSrv.notifyMsg(
                "success",
                "Société ajoutée avec succès"
              );
              solve(true);
            } else {
              if (res.msg) this.generalSrv.notifyMsg("error", res.msg);
              else
                this.generalSrv.notifyMsg("error", "Une erreur s'est produite");
              solve(false);
            }
          },
          (err) => {
            this.generalSrv.notifyMsg("error", "Une erreur s'est produite");
            solve(false);
          }
        );
    });
  }
  listeSctTransprts(filter) {
    return new Promise((solve) => {
      this.httpClient.post(environment.apiListSctTransport, filter).subscribe(
        (res: any) => {
          if (res && res.response) {
            solve(res);
          } else {
            if (res.msg) this.generalSrv.notifyMsg("error", res.msg);
            else
              this.generalSrv.notifyMsg("error", "Une erreur s'est produite");
            solve(false);
          }
        },
        (err) => {
          solve(false);
        }
      );
    });
  }
  addHoptal(hotalDatas) {
    return new Promise((solve) => {
      this.httpClient.post(environment.apiAddHopital, hotalDatas).subscribe(
        (res: any) => {
          // solve(res);
          if (res && res.response) {
            this.generalSrv.notifyMsg("success", "Hopital ajoutée avec succès");
            solve(true);
          } else {
            if (res.msg) this.generalSrv.notifyMsg("error", res.msg);
            else
              this.generalSrv.notifyMsg("error", "Une erreur s'est produite");
            solve(false);
          }
        },
        (err) => {
          this.generalSrv.notifyMsg("error", "Une erreur s'est produite");
          solve(false);
        }
      );
    });
  }

  listeHopitaux(filter) {
    return new Promise((solve) => {
      this.httpClient.post(environment.apiListHopitaux, filter).subscribe(
        (res: any) => {
          if (res && res.response) {
            solve(res);
          } else {
            if (res.msg) this.generalSrv.notifyMsg("error", res.msg);
            else
              this.generalSrv.notifyMsg("error", "Une erreur s'est produite");
            solve(false);
          }
        },
        (err) => {
          solve(false);
        }
      );
    });
  }
  addAssistance(assistDatas) {
    return new Promise((solve) => {
      this.httpClient.post(environment.apiAddAssistance, assistDatas).subscribe(
        (res: any) => {
          // solve(res);
          if (res && res.response) {
            this.generalSrv.notifyMsg(
              "success",
              "Société d'assistance ajoutée avec succès"
            );
            solve(true);
          } else {
            if (res.msg) this.generalSrv.notifyMsg("error", res.msg);
            else
              this.generalSrv.notifyMsg("error", "Une erreur s'est produite");
            solve(false);
          }
        },
        (err) => {
          this.generalSrv.notifyMsg("error", "Une erreur s'est produite");
          solve(false);
        }
      );
    });
  }
  listeAssistance(filter) {
    return new Promise((solve) => {
      this.httpClient.post(environment.apiListSctAssistance, filter).subscribe(
        (res: any) => {
          if (res && res.response) {
            solve(res);
          } else {
            if (res.msg) this.generalSrv.notifyMsg("error", res.msg);
            else
              this.generalSrv.notifyMsg("error", "Une erreur s'est produite");
            solve(false);
          }
        },
        (err) => {
          solve(false);
        }
      );
    });
  }

  listeChauffeurs(filter) {
    return new Promise((solve) => {
      this.httpClient.post(environment.apiListChauffeurs, filter).subscribe(
        (res: any) => {
          if (res && res.response) {
            solve(res);
          } else {
            if (res.msg) this.generalSrv.notifyMsg("error", res.msg);
            else
              this.generalSrv.notifyMsg("error", "Une erreur s'est produite");
            solve(false);
          }
        },
        (err) => {
          solve(false);
        }
      );
    });
  }
  deleteChauff(idchf) {
    return new Promise((solve) => {
      this.httpClient
        .post(environment.apiDeleteChauffeurs, { chid: idchf })
        .subscribe(
          (res: any) => {
            if (res && res.response) {
              solve(res);
            } else {
              if (res.msg) this.generalSrv.notifyMsg("error", res.msg);
              else
                this.generalSrv.notifyMsg("error", "Une erreur s'est produite");
              solve(false);
            }
          },
          (err) => {
            solve(false);
          }
        );
    });
  }
  updateChauffStatus(chauffdatas) {
    return new Promise((solve) => {
      this.httpClient
        .post(environment.apiUpdateStatusChauffeurs, chauffdatas)
        .subscribe(
          (res: any) => {
            if (res && res.response) {
              solve(res);
            } else {
              if (res.msg) this.generalSrv.notifyMsg("error", res.msg);
              else
                this.generalSrv.notifyMsg("error", "Une erreur s'est produite");
              solve(false);
            }
          },
          (err) => {
            solve(false);
          }
        );
    });
  }
  addCommentaire(comdatas) {
    return new Promise((solve) => {
      this.httpClient.post(environment.apiAddComment, comdatas).subscribe(
        (res: any) => {
          if (res && res.response) {
            solve(res);
          } else {
            if (res.msg) this.generalSrv.notifyMsg("error", res.msg);
            else
              this.generalSrv.notifyMsg("error", "Une erreur s'est produite");
            solve(false);
          }
        },
        (err) => {
          solve(false);
        }
      );
    });
  }
  listPayments(filtreData) {
    return new Promise((solve) => {
      this.httpClient.post(environment.apiListPayment, filtreData).subscribe(
        (res: any) => {
          if (res && res.response) {
            solve(res);
          } else {
            if (res.msg) this.generalSrv.notifyMsg("error", res.msg);
            else
              this.generalSrv.notifyMsg("error", "Une erreur s'est produite");
            solve(false);
          }
        },
        (err) => {
          solve(false);
        }
      );
    });
  }
}
