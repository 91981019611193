import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
registerLocaleData(localeFr);

import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";

import { NotifierModule, NotifierOptions } from "angular-notifier";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpInterceptorService } from "./services/http-interceptor.service";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

import { LoginComponent } from "./login/login.component";
import { FooterComponent } from "./components/shared/footer/footer.component";
import { MenuComponent } from "./components/shared/menu/menu.component";
import { TopComponent } from "./components/shared/top/top.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component"; 

import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { AddSctTrasportComponent } from './pages/add-sct-trasport/add-sct-trasport.component';
import { ListSctTransportComponent } from './pages/list-sct-transport/list-sct-transport.component';
import { ListHopitauxComponent } from './pages/list-hopitaux/list-hopitaux.component';
import { ListSctAssistanceComponent } from './pages/list-sct-assistance/list-sct-assistance.component';
import { ListChauffeursComponent } from './pages/list-chauffeurs/list-chauffeurs.component';
import { ListCoursesComponent } from './pages/list-courses/list-courses.component';
import { CoursesRelanceeComponent } from './pages/courses-relancee/courses-relancee.component';
import { PaymentsComponent } from './pages/payments/payments.component';
import { BannersComponent } from './pages/banners/banners.component'; 

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: "right",
    },
    vertical: {
      position: "top",
    },
  },
  theme: "material",
};
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FooterComponent,
    MenuComponent,
    TopComponent,
    DashboardComponent,
    AddSctTrasportComponent,
    ListSctTransportComponent,
    ListHopitauxComponent,
    ListSctAssistanceComponent,
    ListChauffeursComponent,
    ListCoursesComponent,
    CoursesRelanceeComponent,
    PaymentsComponent,
    BannersComponent, 
  ],
  imports: [
    GooglePlaceModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    NotifierModule.withConfig(customNotifierOptions),
    DataTablesModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
