import { Component, OnInit } from "@angular/core";
import { NotifierService } from "angular-notifier";

import { UsersService } from "../services/users/users.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  public msg: String = "";
  private auth: any = { password: "", username: "" };
  constructor(
    private authService: UsersService,
    private notifService: NotifierService
  ) {}

  ngOnInit(): void {}

  authentification() {
    // location.href = '/dashboard';
    this.authService.login(this.auth).then((resp: any) => {
      if (resp && resp.response) {
        location.href = "/dashboard";
      } else {
        this.msg = resp.msg;
        this.notifService.show({
          type: "error",
          message: resp.msg,
        });
      }
    });
  }
}
