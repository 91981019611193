import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { from as fromPromise, Observable, throwError } from 'rxjs';
import {
  catchError,
  filter,
  take,
  switchMap,
  finalize,
  timeout,
} from 'rxjs/operators';
import { UsersService } from './users/users.service';

declare function show_Loading(): any;
declare function hide_Loading(): any;

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private usrSrv: UsersService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    show_Loading();
    return fromPromise(this.handleAccess(request, next));
  }

  private async handleAccess(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    const uinfos: any = await this.usrSrv.getUserInfos();
    let token = '';
    if (uinfos && uinfos.token) {
      token = uinfos.token;
    }
    // alert(token)
    let changedRequest = request;
    // HttpHeader object immutable - copy values
    const headerSettings: { [name: string]: string | string[] } = {};

    for (const key of request.headers.keys()) {
      headerSettings[key] = request.headers.getAll(key);
    }
    if (token) {
      headerSettings['Authorization'] = 'Bearer ' + token;
    }
    // headerSettings["Content-Type"] = "application/json";
    const newHeader = new HttpHeaders(headerSettings);

    changedRequest = request.clone({
      headers: newHeader,
    });
    return next
      .handle(changedRequest)
      .pipe(
        timeout(5000),
        finalize(() => {
          hide_Loading();
        }),
        catchError((error: HttpErrorResponse) => {
          // alert(error);
          return throwError(error);
        })
      )
      .toPromise();
    // return next.handle(changedRequest).toPromise();
  }
}
