import { Component, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
// import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Router, ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import fr from "moment/locale/fr";
declare var jQuery: any;
moment.defineLocale("fr", fr);
import { Subject } from "rxjs";
import { UsersService } from "src/app/services/users/users.service";
import { GeneralService } from "src/app/services/general/general.service";
import { CoursesService } from "src/app/services/courses.service";

@Component({
  selector: "app-list-courses",
  templateUrl: "./list-courses.component.html",
  styleUrls: ["./list-courses.component.css"],
})
export class ListCoursesComponent implements OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  lstcourses: any = [];
  currCourse: any = {};

  dtOptions: DataTables.Settings = {};
  dtTrigger: any = new Subject();
  moreFilter: any = {};
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private generalsrv: GeneralService,
    private userSrvs: UsersService,
    private crsSrv: CoursesService
  ) {}

  ngOnInit() {
    jQuery(".daterange-btn").daterangepicker(
      {
        locale: this.generalsrv.dateTimePickerLocale["fr"],
        onChange: function (start, end) {
          console.log("aaze");

          // var tempValues = JSON.parse($('#daterangepicker').val());
          // alert('change : ' + tempValues.start + ' - ' + tempValues.end)
        },
      },
      (start, end) => this.dateTimeCB(start, end)
    );

    this.activeRoute.queryParams.subscribe((params) => {
      console.log(params);
      if (params) {
        if (params.chauff && params.chauffid) {
          this.moreFilter.createdBy = params.chauffid;
        } else if (params.scte && params.scteid) {
          this.moreFilter.createdBy = params.scteid;
        } else if (params.hopt && params.hop) {
          this.moreFilter.createdBy = params.hop;
        } else if (params.sctassist && params.assist) {
          this.moreFilter.createdBy = params.assist;
        }
      }
      this.loadCourses();
    });
  }
  dateTimeCB(start, end) {
    console.log("start " + start);
    $(".daterange-btn span").html(
      start.format("DD/MM/YYYY") + " - " + end.format("DD/MM/YYYY")
    );
    this.moreFilter.date =
      start.format("DD/MM/YYYY") + " - " + end.format("DD/MM/YYYY");

    this.rerender();
  }
  loadCourses() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 50,
      serverSide: true,
      processing: true,
      ordering: false,
      language: this.generalsrv.dataTableLanguage,
      ajax: (dataTablesParameters: any, callback) => {
        if (this.moreFilter) dataTablesParameters.moreFilter = this.moreFilter;
        this.crsSrv.listeCrouses(dataTablesParameters).then((resp: any) => {
          if (resp) {
            if (resp.data) {
              this.lstcourses = resp.data.table;
              // console.log(resp.data);
              callback({
                recordsTotal: resp.data.total,
                recordsFiltered: resp.data.total,
                data: [],
              });
            }
          } else {
          }
        });
      },
    };
  }

  detailsCourse(chaff) {
    if (chaff) {
      var crss = this.lstcourses.findIndex((x) => x.id.toString() == chaff);

      if (crss != -1) {
        this.currCourse = this.lstcourses[crss];

        jQuery("#detailsCourseModal").modal("show");
      } else {
        console.log("No chauffeur");
      }
    }
  }
  deleteSct(sctID) {
    if (sctID) {
      // jQuery("#delclientmodal").modal("show");
      // this.clienToDel = clientID;
    }
  }
  filterByStatus(s) {
    this.moreFilter = { etat: s };
    this.rerender();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
    });
  }
}
