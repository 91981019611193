// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
var baseURLAPI = "https://api.im-pec.fr/";

export const environment = {
  production: false,
  apiAuthUsers: baseURLAPI + "users/sso",
  apiProfileUsers: baseURLAPI + "users/profile",
  apiApiUploadDoc: baseURLAPI + "upload",
  apiAddSctTransport: baseURLAPI + "admin/addsct",
  apiListSctTransport: baseURLAPI + "admin/sctranpsrt",
  apiAddHopital: baseURLAPI + "admin/addhopital",
  apiListHopitaux: baseURLAPI + "admin/hopitaux",
  apiAddAssistance: baseURLAPI + "admin/addassistance",
  apiListSctAssistance: baseURLAPI + "admin/assistances",

  apiDashboardStat: baseURLAPI + "admin/dashboard",

  apiListChauffeurs: baseURLAPI + "admin/chauffeurs",
  apiDeleteChauffeurs: baseURLAPI + "admin/delchauff",
  apiUpdateStatusChauffeurs: baseURLAPI + "admin/updatechauffstatus",

  apiListPayment: baseURLAPI + "admin/payments",

  apiAddComment: baseURLAPI + "admin/chauffcomment",

  apiListCourses: baseURLAPI + "admin/courses",

  apiUploadBanner: baseURLAPI + "admin/banner/upload/banner",
  apiListBanners: baseURLAPI + "admin/banner/list",
  apiAddBanners: baseURLAPI + "admin/banner/add",
  apiUpdateBanners: baseURLAPI + "admin/banner/edit",
  apiDelBanners: baseURLAPI + "admin/banner/del",

  baseImagesURL: baseURLAPI + "public/uploads/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
