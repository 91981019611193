import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import jwt_decode from "jwt-decode";
import * as moment from "moment";
import fr from "moment/locale/fr";
import { isThisWeek } from "date-fns";
declare var jQuery: any;
moment.defineLocale("fr", fr);

import { UsersService } from "src/app/services/users/users.service";

@Component({
  selector: "app-add-sct-trasport",
  templateUrl: "./add-sct-trasport.component.html",
  styleUrls: ["./add-sct-trasport.component.css"],
})
export class AddSctTrasportComponent implements OnInit {
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  geocompleteoptions: any = {
    types: [],
    componentRestrictions: { country: "FR" },
  };
  newClient: any = {
    enseigne: "",
    note: "",
    email: "",
    password: "",
    tel_mobile: "",
    tel_fixe: "",
    adresse: "",
    ville: "",
    codepostal: "",
    isActive: true,
  };
  minDate: any = moment();
  constructor(private router: Router, private usrSrv: UsersService) {}

  ngOnInit() {}
  handleAddressChange(address) {
    if (address && address.formatted_address && address.address_components) {
      let { formatted_address, geometry, address_components } = address;
      this.newClient.geodatas = {
        formatted_address,
        geometry,
        address_components,
      };
      this.newClient.adresse = formatted_address;
      this.newClient.coords = geometry.location;

      address.address_components.forEach((address_component) => {
        if (address_component.types[0] == "locality") { 
          this.newClient.ville = address_component.long_name;
        }
        if (address_component.types[0] == "postal_code") {
          console.log("pc:" + address_component.long_name);
          this.newClient.codepostal = address_component.long_name;
        }
      });
    } else {
      this.newClient.coords = {};
    }
  }
  addSociete() {
    this.usrSrv.addStcTransport(this.newClient).then((v) => {
      if (v) this.router.navigate(["/prospects"]);
    });
  }
}
