import { Injectable } from "@angular/core";
import { NotifierService } from "angular-notifier";
// import { environment } from "src/environments/environment";
import Swal from 'sweetalert2/dist/sweetalert2.js';


@Injectable({
  providedIn: "root",
})
export class GeneralService {
  dateTimePickerLocale = {
    fr: {
      closeText: "Fermer",
      prevText: "Précédent",
      nextText: "Suivant",
      applyLabel: "Valider",
      cancelLabel: "Annuler",
      fromLabel: "De",
      toLabel: "A",
      customRangeLabel: "Personnalisé",
      days: [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
        "Dimanche",
      ],
      daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
      daysMin: ["D", "L", "Ma", "Me", "J", "V", "S", "D"],
      monthNames: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ],
      monthsShort: [
        "Jan",
        "Fev",
        "Mar",
        "Avr",
        "Mai",
        "Jui",
        "Jul",
        "Aou",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      today: "Aujourd'hui",
      suffix: [],
      meridiem: ["am", "pm"],
      weekStart: 1,
      format: "DD/MM/YYYY HH:mm",
      dateFormat: "DD/MM/YYYY",
    },
  };
  dataTableLanguage: any = {
    emptyTable: "Aucune donnée disponible dans le tableau",
    lengthMenu: "Afficher _MENU_ éléments",
    loadingRecords: "Chargement...",
    processing: "Traitement...",
    zeroRecords: "Aucun élément correspondant trouvé",
    paginate: {
      first: "Premier",
      last: "Dernier",
      next: "Suivant",
      previous: "Précédent",
    },
    aria: {
      sortAscending: ": activer pour trier la colonne par ordre croissant",
      sortDescending: ": activer pour trier la colonne par ordre décroissant",
    },
    select: {
      rows: {
        _: "%d lignes sélectionnées",
        "0": "Aucune ligne sélectionnée",
        "1": "1 ligne sélectionnée",
      },
      "1": "1 ligne selectionnée",
      _: "%d lignes selectionnées",
      cells: {
        "1": "1 cellule sélectionnée",
        _: "%d cellules sélectionnées",
      },
      columns: {
        "1": "1 colonne sélectionnée",
        _: "%d colonnes sélectionnées",
      },
    },
    autoFill: {
      cancel: "Annuler",
      fill: "Remplir toutes les cellules avec <i>%d</i>",
      fillHorizontal: "Remplir les cellules horizontalement",
      fillVertical: "Remplir les cellules verticalement",
      info: "Exemple de remplissage automatique",
    },
    searchBuilder: {
      conditions: {
        date: {
          after: "Après le",
          before: "Avant le",
          between: "Entre",
          empty: "Vide",
          equals: "Egal à",
          not: "Différent de",
          notBetween: "Pas entre",
          notEmpty: "Non vide",
        },
        number: {
          between: "Entre",
          empty: "Vide",
          equals: "Egal à",
          gt: "Supérieur à",
          gte: "Supérieur ou égal à",
          lt: "Inférieur à",
          lte: "Inférieur ou égal à",
          not: "Différent de",
          notBetween: "Pas entre",
          notEmpty: "Non vide",
        },
        string: {
          contains: "Contient",
          empty: "Vide",
          endsWith: "Se termine par",
          equals: "Egal à",
          not: "Différent de",
          notEmpty: "Non vide",
          startsWith: "Commence par",
        },
        array: {
          equals: "Egal à",
          empty: "Vide",
          contains: "Contient",
          not: "Différent de",
          notEmpty: "Non vide",
          without: "Sans",
        },
      },
      add: "Ajouter une condition",
      button: {
        "0": "Recherche avancée",
        _: "Recherche avancée (%d)",
      },
      clearAll: "Effacer tout",
      condition: "Condition",
      data: "Donnée",
      deleteTitle: "Supprimer la règle de filtrage",
      logicAnd: "Et",
      logicOr: "Ou",
      title: {
        "0": "Recherche avancée",
        _: "Recherche avancée (%d)",
      },
      value: "Valeur",
    },
    searchPanes: {
      clearMessage: "Effacer tout",
      count: "{total}",
      title: "Filtres actifs - %d",
      collapse: {
        "0": "Volet de recherche",
        _: "Volet de recherche (%d)",
      },
      countFiltered: "{shown} ({total})",
      emptyPanes: "Pas de volet de recherche",
      loadMessage: "Chargement du volet de recherche...",
    },
    buttons: {
      copyKeys:
        "Appuyer sur ctrl ou u2318 + C pour copier les données du tableau dans votre presse-papier.",
      collection: "Collection",
      colvis: "Visibilité colonnes",
      colvisRestore: "Rétablir visibilité",
      copy: "Copier",
      copySuccess: {
        "1": "1 ligne copiée dans le presse-papier",
        _: "%ds lignes copiées dans le presse-papier",
      },
      copyTitle: "Copier dans le presse-papier",
      csv: "CSV",
      excel: "Excel",
      pageLength: {
        "-1": "Afficher toutes les lignes",
        "1": "Afficher 1 ligne",
        _: "Afficher %d lignes",
      },
      pdf: "PDF",
      print: "Imprimer",
    },
    decimal: ",",
    info: "Affichage de _START_ à _END_ sur _TOTAL_ éléments",
    infoEmpty: "Affichage de 0 à 0 sur 0 éléments",
    infoFiltered: "(filtrés de _MAX_ éléments au total)",
    infoThousands: ".",
    search: "Rechercher:",
    searchPlaceholder: "...",
    thousands: ".",
  };
  constructor(private notifService: NotifierService) {}
  notifyMsg(type = "info", msg = "") {
    this.notifService.show({
      type: type,
      message: msg,
    });
  }
  confirmMsg(msg, ysBtn = "Oui", notBtn = "Non") {
    return new Promise((slv) => {
      Swal.fire({
        title: msg,
        showDenyButton: true,
        confirmButtonText: ysBtn,
        denyButtonText: notBtn,
      }).then((result) => {
        if (result.isConfirmed) {
          // Swal.fire("Saved!", "", "success");
          slv(true);
        } else if (result.isDenied) {
          // Swal.fire("Changes are not saved", "", "info");
          slv(false);
        }
      });
    });
  }
}
