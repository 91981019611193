import { Component, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { GeneralService } from "src/app/services/general/general.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import * as moment from "moment";
import fr from "moment/locale/fr";
import { UsersService } from "src/app/services/users/users.service";
declare var jQuery: any;
moment.defineLocale("fr", fr);
import { Subject } from "rxjs";

@Component({
  selector: "app-list-sct-transport",
  templateUrl: "./list-sct-transport.component.html",
  styleUrls: ["./list-sct-transport.component.css"],
})
export class ListSctTransportComponent implements OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  scts: any = [];
  dtOptions: DataTables.Settings = {};
  dtTrigger: any = new Subject();
  comdatas: any = { userid: "", comment: "" };
  constructor(
    private router: Router,
    private generalsrv: GeneralService,
    private userSrvs: UsersService
  ) {}

  ngOnInit() {
    this.loadSocietes();
  }
  loadSocietes() {
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 50,
      serverSide: true,
      processing: true,
      ordering: false,
      language:this.generalsrv.dataTableLanguage,
      ajax: (dataTablesParameters: any, callback) => {
        this.userSrvs
          .listeSctTransprts(dataTablesParameters)
          .then((resp: any) => {
            if (resp) {
              this.scts = resp.data;
              console.log(resp.data);
              callback({
                recordsTotal: resp.data.length,
                recordsFiltered: resp.data.length,
                data: [],
              });
            } else {
            }
          });
      },
    };
  }
  deleteSct(sctID) {
    if (sctID) {
      // jQuery("#delclientmodal").modal("show");
      // this.clienToDel = clientID;
    }
  }
  displayAddCommentaire(idhop) {
    if (idhop) {
      var chff = this.scts.findIndex((x) => x.id.toString() == idhop);

      if (chff != -1) {
        this.comdatas.userid = idhop;
        this.comdatas.comment = this.scts[chff].comment_user;
        // console.log(this.currChauff);
        jQuery("#addcommentmodal").modal("show");
      } else {
        console.log("No chauffeur");
      }
    }
  }
  addCommentaire() {
    this.userSrvs.addCommentaire(this.comdatas).then((isok: any) => {
      console.log(isok);
      if (isok && isok.response) {
        this.comdatas = { userid: "", comment: "" };
        this.generalsrv.notifyMsg("success", "Commentaire ajouté avec succès");
        jQuery("#addcommentmodal").modal("hide");
        this.rerender();
      }
    });
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => { 
      dtInstance.ajax.reload();
    });
  }
}
