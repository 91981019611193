import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { GeneralService } from "../general/general.service";
@Injectable({
  providedIn: "root",
})
export class BannersService {
  constructor(
    private httpClient: HttpClient,
    private generalSrv: GeneralService
  ) {}
  getBanners(filter = {}) {
    return new Promise((solve) => {
      this.httpClient.post(environment.apiListBanners, filter).subscribe(
        (res: any) => {
          if (res && res.response && res.data) {
            solve(res.data);
          } else {
            if (res.msg) this.generalSrv.notifyMsg("error", res.msg);
            else
              this.generalSrv.notifyMsg("error", "Une erreur s'est produite");
            solve(false);
          }
        },
        (err) => {
          solve(false);
        }
      );
    });
  }
  uploadBanner(imgban) {
    return new Promise((slv) => {
      const formData = new FormData();
      formData.append("fichier", imgban, imgban.name);

      this.httpClient.post(environment.apiUploadBanner, formData).subscribe(
        (res: any) => {
          if (res && res.response && res.fichier) {
            slv(res.fichier);
          } else {
            if (res.msg) this.generalSrv.notifyMsg("error", res.msg);
            else
              this.generalSrv.notifyMsg(
                "error",
                "Impossible de charger l'image"
              );
            slv(false);
          }
        },
        (err) => {
          slv(false);
        }
      );
    });
  }

  addBanner(banner) {
    return new Promise((solve) => {
      this.httpClient.post(environment.apiAddBanners, banner).subscribe(
        (res: any) => {
          if (res && res.response) {
            this.generalSrv.notifyMsg("success", "Bannière ajouté avec succès");
            solve(true);
          } else {
            if (res.msg) this.generalSrv.notifyMsg("error", res.msg);
            else
              this.generalSrv.notifyMsg("error", "Une erreur s'est produite");
            solve(false);
          }
        },
        (err) => {
          solve(false);
        }
      );
    });
  }
  updateBanner(banner) {
    return new Promise((solve) => {
      this.httpClient.post(environment.apiUpdateBanners, banner).subscribe(
        (res: any) => {
          if (res && res.response) {
            this.generalSrv.notifyMsg(
              "success",
              "Bannière modifié avec succès"
            );
            solve(true);
          } else {
            if (res.msg) this.generalSrv.notifyMsg("error", res.msg);
            else
              this.generalSrv.notifyMsg("error", "Une erreur s'est produite");
            solve(false);
          }
        },
        (err) => {
          solve(false);
        }
      );
    });
  }
  deleteBanner(banner) {
    return new Promise((solve) => {
      this.httpClient.post(environment.apiDelBanners, banner).subscribe(
        (res: any) => {
          if (res && res.response) {
            this.generalSrv.notifyMsg(
              "success",
              "Bannière supprimé avec succès"
            );
            solve(true);
          } else {
            if (res.msg) this.generalSrv.notifyMsg("error", res.msg);
            else
              this.generalSrv.notifyMsg("error", "Une erreur s'est produite");
            solve(false);
          }
        },
        (err) => {
          solve(false);
        }
      );
    });
  }
}
